<template>
  <div class="container">
    <div class="head">
      <img src="../../assets/images/yuwan/registrationHead.jpg" />
    </div>
    <div class="content">
      <div class="baseInfo">
        <div style="margin-top: 20px">
          <div class="baseTitle">收款信息填写</div>
          <div class="infoMassage">
            <div class="leftTit">收款人姓名:</div>
            <div class="pass_input">
              <el-input
                v-model="formInline.receiver_name"
                placeholder="请填写姓名"
              />
            </div>
          </div>
          <div class="infoMassage">
            <div class="leftTit">手机号:</div>
            <div class="pass_input">
              <el-input
                maxlength="11"
                v-model.trim="formInline.receiver_phone"
                placeholder="请填写手机号"
              />
            </div>
          </div>
          <div class="infoMassage">
            <div class="leftTit">开户行:</div>
            <div class="pass_input">
              <el-input
                v-model="formInline.bank_name"
                placeholder="请填写开户行"
              />
            </div>
          </div>
          <div class="infoMassage">
            <div class="leftTit">收款账户:</div>
            <div class="pass_input">
              <el-input
                maxlength="19"
                v-model.trim="formInline.receiver_account"
                placeholder="请填写收款账户"
              />
            </div>
          </div>
          <div class="infoMassage">
            <div class="leftTit">银行联行号:</div>
            <div class="pass_input">
              <el-input
                maxlength="13"
                v-model.trim="formInline.bank_connection_number"
                placeholder="请填写银行联行号"
              />
            </div>
          </div>
          <div class="infoMassage" style="border-bottom: 1px solid #454544">
            <div class="leftTit">收款人身份证:</div>
            <div class="pass_input">
              <el-input
                maxlength="18"
                v-model="formInline.receiver_id_num"
                placeholder="请填写身份证"
              />
            </div>
          </div>
          <div class="submit">
            <el-button class="inquireBtn" type="primary" @click="submitBtn"
              >提交</el-button
            >
          </div>
        </div>
      </div>
      <div class="descTitle">
        <p class="matter">&nbsp;&nbsp;提示信息：</p>
        <p>奖金发放登记截止时间为2025年1月12日24：00，请尽快完成登记。</p>
        <p>
          1、一组队伍只允许填写一次收款信息，以先填写的信息为准。（赛道二由队长填写）
        </p>
        <p>
          2、登记信息时请填写身份证号，由竞赛方代缴个人偶然所得税（20%），奖金以实收金额为准。
        </p>
        <p>
          3、奖金发放周期为登记截止后20个工作日内。已收到奖金的选手无法再次登记，发放完成后由参赛队伍自行分配。
        </p>
        <p>
          4、如更换手机号无法验证，请优先使用其他队员手机进行验证。仍无法验证的请使用竞赛报名登记的邮箱发送邮件至dtcupzuwei@126.com，提供与报名信息一致的收款信息。邮箱反馈仅限更改手机号的获奖选手，其他情况无效。
        </p>
        <p>
          5、请仔细核对并如实填写收款信息，一经填写无法修改，填写样例如下，个人信息登记不规范会导致发放延期。
        </p>
        <p class="footMessage">收款人姓名：张三</p>
        <p class="footMessage">开户行：中国银行北京XXX支行/XXX营业所</p>
        <p class="footMessage">收款账户：6222123412341234567</p>
        <p class="footMessage">联行号：103123412345</p>
        <p class="footMessage">收款人身份证：与收款人姓名匹配，如实填写</p>
      </div>
    </div>
  </div>
</template>
 
<script>
import { ElMessage } from "element-plus";
import { competition } from "api/prize.js";
export default {
  data() {
    return {
      formInline: {
        phone: "",
        receiver_name: "",
        receiver_phone: "",
        bank_name: "",
        receiver_account: "",
        bank_connection_number: "",
        receiver_id_num: "",
      },
      dataList: [],
      personInfo: [], //个人基础信息
    };
  },
  mounted() {},
  methods: {
    verificationFun() {
      let status = true;
      let reg = 11 && /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/;
      let idCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      let accountNumberRegex = /^[1-9]{1}[0-9]{15,18}$/;
      let bankCodeRegex = /^[1-9]{1}[0-9]{7,11}$/;
      if (this.formInline.receiver_name == "") {
        this.$message.error("收款人姓名不能为空");
        status = false;
      } else if (this.formInline.receiver_phone == "") {
        this.$message.error("手机号为空");
        status = false;
      } else if (!reg.test(this.formInline.receiver_phone)) {
        this.$message.error("手机格式不正确");
        status = false;
      } else if (this.formInline.bank_name == "") {
        this.$message.error("开户行不能为空");
        status = false;
      } else if (this.formInline.receiver_account == "") {
        this.$message.error("收款账户不能为空");
        status = false;
      } else if (!accountNumberRegex.test(this.formInline.receiver_account)) {
        this.$message.error("收款账户格式错误");
        status = false;
      } else if (this.formInline.bank_connection_number == "") {
        this.$message.error("银行联行号不能为空");
        status = false;
      } else if (!bankCodeRegex.test(this.formInline.bank_connection_number)) {
        this.$message.error("银行联行号格式错误");
        status = false;
      } else if (this.formInline.receiver_id_num == "") {
        this.$message.error("收款人身份证不能为空");
        status = false;
      } else if (!idCardReg.test(this.formInline.receiver_id_num)) {
        this.$message.error("收款人身份证格式不正确");
        status = false;
      }
      this.$forceUpdate();
      return status;
    },

    submitBtn() {
      let flag = this.verificationFun();
      let team_id = localStorage.getItem("team_id");
      let phone = localStorage.getItem("phone");
      console.log(flag);
      if (flag) {
        let param = new FormData();
        param.append("phone", phone);
        param.append("team_id", team_id);
        param.append("receiver_name", this.formInline.receiver_name);
        param.append("receiver_phone", this.formInline.receiver_phone);
        param.append("bank_name", this.formInline.bank_name);
        param.append("receiver_account", this.formInline.receiver_account);
        param.append(
          "bank_connection_number",
          this.formInline.bank_connection_number
        );
        param.append("receiver_id_num", this.formInline.receiver_id_num);
        competition.save(param).then((res) => {
          if (res.code === 200) {
            ElMessage({
              message: res.msg,
              type: "success",
            });
            let idList = localStorage.getItem("idList");
            this.$router.push({
              path: "/InformationCompetition",
              query: { phone, idList },
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./InformationCompetition.scss";
</style>