<template>
    <div>
      <Footer></Footer>
        <div class="content">
          <div class="content_box">
           <span class="content_box_tilte"> 第十一届“大唐杯”全国大学生新一代信息通信技术大赛 官方通知发布</span>
           <div class="content_box_BOX"> 
                <span class="content_inform">官方通知</span>
                <div class="content_text">
									<p v-for="(item,index) in noticeList" :key="index"><el-link type="info" @click="openfile(item)"> {{ item }}</el-link></p>
								</div>
           </div>
          </div>
  
  
          <div class="content_box">
           <span class="content_box_tilte"> 第十一届“大唐杯”全国大学生新一代信息通信技术大赛 官方文件发布</span>
           <div class="content_box_BOXTOW"> 
                <span class="content_inform">官方文件</span>
                <div class="content_text">
									<p v-for="(item,index) in fileList" :key="index"><el-link type="info" @click="openfile(item)"> {{ item }}</el-link></p>
                </div>
           </div>
          </div>
        </div>
    <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
  export default {
    name: 'Home',
    components: {
      Footer,
      Bottom
    },
    data(){
      return {
				noticeList:[
					'关于邀请参加第十一届“大唐杯”全国大学生新一代信息通信技术大赛的通知',
					'关于邀请参加第十一届“大唐杯”全国双师型教师新一代信息通信技术大赛的通知',
					'关于邀请参加第十一届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛的通知',
					'第十一届“大唐杯”全国大学生新一代信息通信技术大赛信息通信工程实践赛（省赛）竞赛安排通知'
				],
				fileList:[
          '第十一届大唐杯全国大学生新一代信息通信技术大赛-获奖名单公布',
          '第十一届大唐杯全国双师型教师新一代信息通信技术大赛-竞赛大纲',
          '第十一届“大唐杯”全国双师型教师新一代信息通信技术大赛竞赛安排',
          '第十一届“大唐杯”全国双师型教师新一代信息通信技术大赛获奖名单公布',
          '第十一届大唐杯全国大学生新一代信息通信技术大赛全国总决赛-竞赛大纲',
          '第十一届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛现场安排',
          '第十一届“大唐杯”全国大学生新一代信息通信技术大赛总决赛获奖名单公布',
          '第十一届大唐杯全国大学生新一代信息通信技术大赛（产教融合5G+创新应用设计）专项赛说明',
          '第十一届大唐杯全国大学生新一代信息通信技术大赛（省赛）-信息通信工程实践赛道竞赛大纲',
          
          
				]
      }
    },
    created(){},
    methods:{
			openfile(file){
        window.open(`./static/Eleth/${file}.pdf`)
      },
    },
    mounted(){
  
    },
    created(){
  
    },
  }
  </script>
  
  <style scoped lang="scss">
  /deep/ .el-table .cell.el-tooltip{
    text-align: left;
  }
  /deep/.el-link__inner{
    color: #000;
  }
  /deep/.el-link__inner:hover{
    color: orangered;
  }
  
  @import './Eleth.scss';
  </style>